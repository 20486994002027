<template>
	<b-modal
		id="undo-delete-blog"
		ref="undo-delete-blog-modal"
		title="Undo Delete"
		:no-close-on-backdrop="true"
		@hidden="onReset"
		size="md"
	>
		<loading
			:active.sync="isLoading"
			loader="spinner"
			color="#20A8D8"
			:is-full-page="false"
		/>

		<div class="row">
			<div class="col-md-12">
				<h4 class="mb-4">Undo delete for this Blog?</h4>
			</div>
		</div>

		<template v-slot:modal-footer>
			<div class="w-100 text-right">
				<b-button
					size="sm"
					variant="success"
					class="mr-2"
					@click.prevent="submit"
				>
					Undo Delete
				</b-button>
			</div>
		</template>
	</b-modal>
</template>

<script>
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import blogsDAO from '../../../database/blogs';

export default {
	name: 'delete-blog',
	components: { Loading },
	data() {
		return {
			isLoading: false,
			id: '',
		};
	},
	mounted() {
		EventBus.$on('showUndoDeleteBlogDialog', (id) => {
			this.$bvModal.show('undo-delete-blog');
			this.id = id;
		});
	},
	methods: {
		async submit() {
			try {
				this.isLoading = true;

				await blogsDAO.undoDelete(this.id);

				this.$toaster.success('Blog has been activated');
				this.$bvModal.hide('undo-delete-blog');
				EventBus.$emit('onSaveBlog');
			} catch (_error) {
				this.$toaster.error('Error undoing delete blog. Please try again.');
			}

			// hide loading indicator
			this.isLoading = false;
		},
		onReset() {
			this.id = '';
		},
	},
	beforeDestroy() {
		EventBus.$off('showUndoDeleteBlogDialog');
		this.onReset();
	},
};
</script>