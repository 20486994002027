<template>
	<b-modal id="delete-blog" ref="delete-blog-modal" title="Delete Blog" :no-close-on-backdrop="true" @hidden="onReset">
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<div class="row">
			<div class="col-md-12">
				<h4 class="mb-4">Are you sure you want to delete this blog?</h4>
			</div>
		</div>

		<template v-slot:modal-footer>
			<div class="w-100 text-right">
				<b-button v-if="!form.isDeleted" size="sm" variant="warning" class="text-white mr-2"
					@click.prevent="submit(true)">
					Soft Delete
				</b-button>
				<b-button size="sm" variant="danger" @click.prevent="submit()">
					Permanent Delete
				</b-button>
			</div>
		</template>
	</b-modal>
</template>

<script>
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import blogsDAO from '../../../database/blogs';

export default {
	name: 'delete-blog',
	components: { Loading },
	data() {
		return {
			isLoading: false,
			form: {},
		};
	},
	mounted() {
		EventBus.$on('showDeleteBlogDialog', (form) => {
			this.$bvModal.show('delete-blog');
			this.form = { ...form };
		});
	},
	methods: {
		async submit(isSoftDelete) {
			try {
				this.isLoading = true;

				if (isSoftDelete) {
					await blogsDAO.softDelete(this.form.id);
					this.$toaster.success('Blog has been soft deleted!');
				} else {
					await blogsDAO.forceDelete(this.form.id);
					this.$toaster.success('Blog has been deleted!');
				}

				this.$bvModal.hide('delete-blog');
				EventBus.$emit('onSaveBlog');
			} catch (_error) {
				this.$toaster.error('Error deleting blog. Please try again.');
			}

			// hide loading indicator
			this.isLoading = false;
		},
		onReset() {
			this.form = {};
		},
	},
	beforeDestroy() {
		EventBus.$off('showDeleteBlogDialog');
		this.onReset();
	},
};
</script>