<template>
	<b-modal id="update-blog" ref="update-blog-modal" title="Update Blog" :no-close-on-backdrop="true" @hidden="onReset"
		@ok.prevent="onSubmit" ok-title="Update">
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />
		<b-form ref="form" @submit.stop.prevent="onSubmit">
			<b-form-group id="title-input-group" label="Title" label-for="title">
				<b-form-input id="title" name="Title" v-model="form.title" type="text" required
					placeholder="Enter blog title" v-validate="'required'" />
				<span v-show="errors.has('Title')" class="help-block">{{
					errors.first('Title')
				}}</span>
			</b-form-group>

			<b-form-group id="description-input-group" label="Description" label-for="description">
				<b-form-textarea id="description" v-model="form.description" type="text" name="Description"
					placeholder="Enter description" v-validate="'required'" />
				<span v-show="errors.has('Description')" class="help-block">{{
					errors.first('Description')
				}}</span>
			</b-form-group>

			<b-form-group label="Upload Blog Image" label-for="blog-image">
				<b-form-file id="blog-image" placeholder="Choose image" ref="fileInput" accept="image/*"
					@change="onSelectImage">
					<template slot="file-name" slot-scope="{ names }">
						<b-badge variant="dark">{{ names[0] }}</b-badge>
						<b-badge v-if="names.length > 1" variant="dark" class="ml-1">+ {{ names.length - 1 }} More
							files</b-badge>
					</template>
				</b-form-file>
			</b-form-group>

			<b-form-group v-if="image.url">
				<b-card overlay :img-src="image.url" img-alt="Card Image" text-variant="white" class="mb-0">
					<b-card-text class="text-right">
						<b-button variant="danger" size="sm" @click="onRemoveImage">
							<i class="fa fa-trash"></i>
						</b-button>
					</b-card-text>
				</b-card>
				<b-progress v-if="uploading.uploadStatus === 'uploading'" :value="uploading.percentage"
					:max="uploading.filename" variant="success" height="8px" animated />
			</b-form-group>
		</b-form>
	</b-modal>
</template>

<script>
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { DateUtil } from '@/utils/dateutil';
import _ from 'lodash';

// Database
import blogsDAO from '../../../database/blogs';

// Firebase Storage
import { storage } from '../../../config/firebase';

export default {
	name: 'update-blog',
	components: { Loading },
	data() {
		return {
			isLoading: false,
			form: {
				title: '',
				description: '',
				createdBy: this.$store.getters['email'],
				isDeleted: false,
				dateCreated: DateUtil.getCurrentTimestamp(),
				dateUpdated: DateUtil.getCurrentTimestamp(),
				dateDeleted: null,
			},
			image: {},
			uploading: {
				filename: '',
				percentage: 0,
				uploadStatus: '', // [uploading, success, error]
			},
		};
	},
	mounted() {
		EventBus.$on('showUpdateBlogDialog', (item) => {
			this.$bvModal.show('update-blog');
			this.form = { ...item };
			this.image = { ...item.image };
		});
	},
	methods: {
		async onSubmit() {
			this.isLoading = true;

			try {
				const isValid = await this.$validator.validateAll();
				if (!isValid) {
					throw 'Please complete the required fields.';
				}

				if (_.isEmpty(this.image)) {
					throw 'Blog Image is required.';
				}

				this.form.updatedBy = this.$store.getters['email'];
				this.form.dateUpdated = Date.now();

				await blogsDAO.update(this.form);

				if (this.image._isNew) {
					// Upload Image
					const imageFile = await this.firebaseUploadImage(this.image);

					// Update hub image url
					await blogsDAO.updateBlogImageUrl(this.form.id, {
						image: imageFile,
					});

					this.uploadingInProgress('success');
				} else if (_.isEmpty(this.image)) {
					// Delete file from bucket and update document
					blogsDAO.deleteBlogImage(this.form.id);
				}

				this.$bvModal.hide('update-blog');
				this.$toaster.success('Blog has been updated!');
				EventBus.$emit('onSaveBlog');
			} catch (_error) {
				this.$toaster.error('Error updating blog. Please try again.');
			}

			// hide loading indicator
			this.isLoading = false;
		},
		async firebaseUploadImage(image) {
			let filename = `${this.form.title}-${Date.now()}`;
			this.uploadingInProgress('uploading', filename, 0);

			return new Promise((resolve, reject) => {
				let storageRef = storage.ref(`blogs/${filename}`);
				let task = storageRef.put(image.file);

				task.on(
					'state_changed',
					(snapshot) => {
						let percentage =
							(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
						this.uploadingInProgress('uploading', filename, percentage);
					},
					(error) => {
						reject(error);
						this.resetUploadingState();
					},
					() => {
						task.snapshot.ref.getDownloadURL().then((downloadURL) => {
							resolve({
								name: filename,
								url: downloadURL,
							});
						});
					}
				);
			});
		},
		uploadingInProgress(uploadStatus, filename = '', percentage = 0) {
			this.uploading.filename = filename;
			this.uploading.percentage = percentage;
			this.uploading.uploadStatus = uploadStatus;
		},
		resetUploadingState() {
			this.uploading = {
				filename: '',
				percentage: 0,
				uploadStatus: '',
			};
		},
		onRemoveImage() {
			this.image = '';
			this.$refs['fileInput'].reset();
		},
		onSelectImage(evt) {
			const file = evt.target.files[0];
			this.image = {
				url: URL.createObjectURL(file),
				file: file,
				_isNew: true,
			};
		},
		onReset() {
			/* Reset our form values */
			this.form = {
				title: '',
				description: '',
				createdBy: this.$store.getters['email'],
				isDeleted: false,
				dateCreated: DateUtil.getCurrentTimestamp(),
				dateUpdated: DateUtil.getCurrentTimestamp(),
				dateDeleted: null,
			};
			this.image = {};

			// reset validation
			this.$validator.reset();
			this.errors.clear();
		},
	},
	beforeDestroy() {
		EventBus.$off('showUpdateBlogDialog');
	},
};
</script>
