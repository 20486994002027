import { db, storage } from '@/config/firebase';

async function retrieve() {
    let blogsObj = {};
    let query = db.collection('blogs');

    query = query.orderBy('dateCreated', 'desc');

    const querySnapshot = await query.get();
    querySnapshot.forEach(doc => {
        let blogObj = doc.data();
        blogObj['id'] = doc.id;
        blogsObj[doc.id] = blogObj;
    });

    return blogsObj;
}

async function create(blog) {
    let query = db.collection('blogs');
    return query.add(blog);
}

async function update(blog) {
    let query = db.collection('blogs').doc(blog.id);
    return query.set(blog, { merge: true });
}

async function updateBlogImageUrl(blogId, imageObj) {
    let query = db.collection('blogs').doc(blogId);
    return query.set(imageObj, { merge: true });
}

// Delete file from bucket and update document
async function deleteBlogImage(blogId, isForceDeleted = false) {
    const query = await db.collection('blogs').doc(blogId).get();
    const data = query.exists ? query.data() : null;
    const imageRef = storage.ref();

    if (data.image.name) {
        const blogImageRef = imageRef.child(`blogs/${data.image.name}`);
        await blogImageRef.delete();
        if (isForceDeleted) {
            await updateBlogImageUrl(blogId, { image: {} });
        }
    }
}

async function undoDelete(id) {
    let query = db.collection('blogs').doc(id);
    return query.set({
        isDeleted: false,
        dateDeleted: null
    }, { merge: true });
}

async function softDelete(id) {
    let query = db.collection('blogs').doc(id);
    return query.set({
        isDeleted: true,
        dateDeleted: Date.now()
    }, { merge: true });
}

async function forceDelete(id) {
    let query = db.collection('blogs').doc(id);
    // Delete image as well
    await deleteBlogImage(id, true);
    return query.delete();
}

export default {
    retrieve,
    create,
    update,
    updateBlogImageUrl,
    deleteBlogImage,
    softDelete,
    undoDelete,
    forceDelete
}